<template>
  <div
    v-if="currentEvents.length > 0"
    class="lg:container mx-auto mt-12 lg:mt-24 grid grid-cols-12 lg:gap-12 xl:gap-24 relative z-10"
  >
    <div
      data-aos="fade-in"
      class="mb-8 col-span-12 lg:mb-0 lg:col-span-6 transition-all lazy shadow-md group-hover:shadow-2xl h-52 md:h-72 lg:h-100 w-full rounded-md background  order-last"
      style="flex: 0 0 auto;"
      :data-bg="'https://storysh.de/site/img/homeEvents.webp'"
      :data-bg-hidpi="'https://storysh.de/site/img/homeEvents.webp'"
    />
    <article
      data-aos="fade-right"
      class="post-card p-4 lg:p-8 shadow-lg md:text-left col-span-12 lg:col-span-6 order-first lg:order-first bg-dark text-white   overlap--tile left--tile event--tile min-h-24 z-20"
    >
      <h2 class="text-white transition-colors mb-6 lg:mb-12 text-left px-3 md:px-0">
        {{ $t('CURRENT_EVENTS') }}
      </h2>
      <div
        v-for="detailItem in currentEvents"
        :key="detailItem.label"
        class="h-paginatedItem lg:h-72 pb-5 lg:pb-5"
        itemscope
        itemtype="http://schema.org/Event"
      >
        <a
          :href="getUrl(detailItem)"
          :title="detailItem.title"
        >
          <div class="text-left flex flex-wrap items-center lg:items-start">
            <div class="w-full text-center md:text-left md:w-3/12 lg:w-2/12">
              <div class="inline-block relative text-center md:text-left w-32 h-32 rounded-full border-4 border-primary overflow-hidden">
                <fallback-image
                  :headline="detailItem.title"
                  :height="'h-32'"
                />
              </div>
            </div>
            <div class="w-full md:w-9/12 lg:w-10/12 mt-5 lg:mt-0">
              <div class="py-5">
                <h4 class="text-left card-title text-xl font-semibold">
                  <span
                    class="pr-3"
                    itemprop="startDate"
                    :content="detailItem.eventDateString + (detailItem.startTime !== '') ? detailItem.startTime : ''"
                  >{{ detailItem.eventDateString }} <span v-if="detailItem.startTime !== ''"> {{ detailItem.startTime }}</span></span> <span itemprop="name">{{ detailItem.title }} <span v-if="detailItem.subTitle !== ''"> - {{ detailItem.subTitle }}</span></span>
                </h4>
                <div class="py-4">
                  <p
                    v-for="(category, categoryIndex) in detailItem.categories"
                    :key="categoryIndex"
                    class="flex flex-wrap text-dark mt-2"
                  >
                    <span class="mr-4 ml-5 text-primary"><icon-tag /></span>
                    {{ category.title }}
                  </p>
                </div>
                <div
                  itemprop="description"
                  v-html="getTeaser(detailItem.teaser)"
                />
              </div>
            </div>
          </div>
        </a>
        <div class="bg-white text-dark rounded-md hidden lg:flex lg:flex-wrap">
          <div class="md:w-2/12" />
          <div
            class="py-3 md:w-10/12"
          >
            <div
              class="pr-3 flex flex-row"
              itemprop="location"
              itemscope
              itemtype="http://schema.org/Place"
            >
              <span
                class="pr-2 pt-0.5"
              >
                <icon-clock />
              </span> 
              <span
                class="pr-3"
                itemprop="streetAddress"
              >{{ detailItem.address }}</span>
              <span
                class="pr-2 pt-0.5"
              >
                <icon-flag />
              </span> 
              <span
                class="pr-1"
                itemprop="postalCode"
              >
                {{ detailItem.zipCode }}
              </span> 
              <span
                class="pr-3"
                itemprop="addressRegion"
              >{{ detailItem.city }}</span>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import axios from "axios";
import { defineAsyncComponent } from "vue";
const FallbackImage = defineAsyncComponent(() => 
  import("../../../../../FrontendBundle/assets/js/components/common/Image/FallbackImage" /* webpackChunkName: "fallback-image" */)
);
const IconClock = defineAsyncComponent(() =>
  import("../../../../../FrontendBundle/assets/js/icons/icons/IconClock" /* webpackChunkName: "image-clock" */)
);
const IconFlag = defineAsyncComponent(() =>
  import("../../../../../FrontendBundle/assets/js/icons/icons/IconFlag" /* webpackChunkName: "image-flag" */)
);
const IconTag = defineAsyncComponent(() =>
  import("../../../../../FrontendBundle/assets/js/icons/icons/IconTag" /* webpackChunkName: "image-tag" */)
);

export default {
  name: "IndexCurrentEvents",
  components: {
    FallbackImage,
    IconTag,
    IconClock,
    IconFlag
  },
  data() {
    return {
      currentEvents: []
    };
  },
  mounted() {
    axios.get(process.env.apiURL + '/fe/api/event/current')
      .then(response =>  {
        this.currentEvents = response.data;
      })
      .catch(function (error) {
      })
      .then(function () {
      });
  },
  methods: {
    getTeaser(teaser) {
      return this.truncate(teaser, 255, '...</p>');
    },
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    getUrl(detailItem) {
      return process.env.frontendURL + '/events/' + detailItem.slug;
    },
    getImageSrc(item, viewport) {
      if (typeof item.images === "undefined") {
        if (item.image.includes('fallback')) {
          return process.env.apiURL + '/site/img/author/list/preview/authorListPreview-' + Math.floor(Math.random() * (26 - 1 + 1) + 1) + '.webp';
        }

        return item.image;
      }

      if (item.images.length === 0) {
        if (item.image.includes('fallback')) {
          return process.env.apiURL + '/site/img/author/list/preview/authorListPreview-' + Math.floor(Math.random() * (26 - 1 + 1) + 1) + '.webp';
        }

        return item.image;
      }

      let imageCount = item.images.length,
        count = 0,
        hasMain = false;

      if (imageCount === 1) {
        if (typeof item.images[0].media.thumbnails === 'undefined' || item.images[0].media.thumbnails.length === 0) {
          if (item.images[0].img.includes('fallback')) {
            return process.env.apiURL + '/site/img/author/list/preview/authorListPreview-' + Math.floor(Math.random() * (26 - 1 + 1) + 1) + '.webp';
          }

          return item.images[0].img;
        }

        if (item.images[0].media.thumbnails[viewport]['list'].img.includes('fallback')) {
          return process.env.apiURL + '/site/img/author/list/preview/authorListPreview-' + Math.floor(Math.random() * (26 - 1 + 1) + 1) + '.webp';
        }

        return item.images[0].media.thumbnails[viewport]['list'].img;
      }

      for (count; count <= imageCount - 1; count++) {
        if (count === imageCount - 1 && hasMain === false) {
          if (item.images[count].media.thumbnails[viewport]['list'].img.includes('fallback')) {
            return process.env.apiURL + '/site/img/author/list/preview/authorListPreview-' + Math.floor(Math.random() * (26 - 1 + 1) + 1) + '.webp';
          }

          return item.images[count].media.thumbnails[viewport]['list'].img;
        }
      }
    }
  }
}
</script>